<template>
  <span>
    <h5>{{ field.label }} Filter</h5>
    <i
      v-if="optionsPending === null || optionsPending"
      class="fas fa-spin fa-circle-notch"
    />
    <template v-else>
      <b-form-group>
        <b-form-input
          v-if="canSearch"
          v-model="searchInput"
          size="sm"
          placeholder="Suche"
          class="mb-2"
        />
        <b-form-checkbox
          v-for="option in searchedOptions"
          :key="label(option)+'2'"
          :checked="isOptionSelected(field.key, option)"
          @change="selectOption(field.key, option, $event)"
        >
          {{ label(option) }}
        </b-form-checkbox>
      </b-form-group>
    </template>
  </span>
</template>

<script>
import mergeQueries from './mergeQueries'
export default {
  name: 'FilterList',
  props: {
    field: {
      type: Object,
      required: true
    },
    opened: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      optionsPending: null,
      selectedOptions: [],
      options: [],
      searchInput: ''
    }
  },
  computed: {
    canSearch () {
      return this.options.length >= (this.field.filter.searchThreshold || 6)
    },
    searchedOptions () {
      return this.options.filter(option => {
        return this.label(option).toLowerCase().includes(this.searchInput.toLowerCase())
      })
    },
    label () {
      return (option) => {
        if (!option.label && this.field.formatter) {
          return this.field.formatter(option.value)
        } else if (!option.label) {
          return option.value
        } else {
          return option.label
        }
      }
    },
    query () {
      let query = { $and: [] }
      const selectedOptionValues = this.selectedOptions.map(x => x.value)
      if (selectedOptionValues.length !== 0) {
        const Q = { }
        Q[this.field.key] = { $in: selectedOptionValues }
        query = mergeQueries(query, { $and: [Q] })
      }
      if (query.$and.length === 0) delete query.$and
      return query
    },
    isOptionSelected () {
      return (key, option) => {
        return this.selectedOptions.some(x => x.value === option.value)
      }
    }
  },
  watch: {
    selectedOptions: {
      handler: 'updateFilterStatus'
    }
  },
  async created () {
    await this.fetchFilterOptions()
    if (this.field.filter.defaultValue) {
      this.selectedOptions.push(...this.field.filter.defaultValue)
    }
  },
  methods: {
    updateFilterStatus () {
      this.$emit('change', {
        isApplied: (this.selectedOptions.length !== 0),
        query: this.query
      })
    },
    selectOption (key, option) {
      const optionIndex = this.selectedOptions.findIndex(selectedOption => selectedOption.value === option.value)
      if (optionIndex !== -1) {
        this.selectedOptions.splice(optionIndex, 1)
      } else {
        this.selectedOptions.push(option)
      }
    },
    async fetchFilterOptions () {
      if (
        (this.optionsPending === false || this.optionsPending === null) &&
        this.field.filter.fetchOptions &&
        this.selectedOptions.length === 0
      ) {
        try {
          this.optionsPending = true
          this.options = await this.field.filter.fetchOptions()
        } catch (error) {
          console.error(error)
        } finally {
          this.optionsPending = false
        }
      }
    },
    deleteFilter () {
      this.selectedOptions = []
      this.searchInput = ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
